<template>
  <div>
    <v-dialog v-model="dialog" style="height: 100vh">
      <v-card>
        <div v-if="dialog">
          <v-img
            v-if="images[selectedIndex].media_type === 'IMAGE'"
            max-height="80vh"
            contain
            eager
            :src="getMediaUrl(images[selectedIndex])"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-carousel
            v-if="images[selectedIndex].media_type === 'CAROUSEL_ALBUM'"
            :continuous="false"
            eager
            hide-delimiters
          >
            <v-carousel-item
              v-for="(image, i) in images[selectedIndex].images"
              :key="i"
            >
              <v-img height="100%" :src="image.media_url" contain></v-img>
            </v-carousel-item>
          </v-carousel>
          <div
            v-if="images[selectedIndex].media_type === 'VIDEO'"
            class="video-wrapper"
          >
            <video ref="video" autoplay controls width="100%">
              <source :src="images[selectedIndex].media_url" type="video/mp4" />
            </video>
          </div>
          <v-row class="navigation">
            <v-col
              class="cursor-left"
              cols="4"
              @click="selectItem(selectedIndex - 1)"
            ></v-col>
            <v-col
              class="cursor-center"
              cols="4"
              @click="closeDialog()"
            ></v-col>
            <v-col
              class="cursor-right"
              cols="4"
              @click="selectItem(selectedIndex + 1)"
            ></v-col>
          </v-row>
        </div>
        <v-card-actions class="d-flex justify-center pa-3">
          <a
            v-if="dialog"
            :href="images[selectedIndex].permalink"
            target="_blank"
            style="text-decoration: unset"
          >
            <v-btn color="primary">
              <v-icon left>fab fa-instagram</v-icon>Instagram
            </v-btn>
          </a>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mgrid">
      <v-card
        flat
        v-for="(item, index) in images"
        :key="index"
        class="overflow-hidden"
        @click="selectItem(index)"
      >
        <div class="overflow-hidden">
          <img width="100%" class="image" :src="getMediaUrl(item)" />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: null,
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.addSwipeListener();
      }
    },
  },
  data() {
    return {
      selectedIndex: -1,
      dialog: false,
    };
  },
  methods: {
    getMediaUrl(item) {
      if (item.media_type === "VIDEO") {
        return item.thumbnail_url;
      } else {
        return item.media_url;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.selectedIndex = -1;
    },
    async selectItem(index) {
      await this.closeDialog();
      if (index >= this.images.length) {
        index = 0;
      } else if (index < 0) {
        index = this.images.length - 1;
      }
      this.selectedIndex = index;
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.mgrid {
  /* Prevent vertical gaps */
  line-height: 0;
  column-count: 3;
  column-gap: 0.5rem;
}

.mgrid div {
  page-break-inside: avoid; /* Theoretically FF 20+ */
  break-inside: avoid-column; /* IE 11 */
  display: table;
  margin-bottom: 0.5rem;
}

@media (max-width: 800px) {
  .mgrid {
    column-count: 2;
  }
}

@media (max-width: 400px) {
  .mgrid {
    column-count: 1;
  }
}

.grid-item {
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.image {
  transition: 1s;
}

.image:hover {
  opacity: 0.5;
  transform: scale(1.2);
}
.cursor-left {
  cursor: url("/static/cursor-left.png"), move;
}

.cursor-center {
  cursor: url("/static/cursor-center.png"), move;
}

.cursor-right {
  cursor: url("/static/cursor-right.png"), move;
}

.caption {
  line-height: 2rem;
}
.video-wrapper {
  display: flex;
  height: 70vh;
  justify-content: center;
}
.navigation {
  height: 100%;
  top: 0;
  width: 100%;
  position: absolute;
}
</style>
