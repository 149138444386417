<template>
  <Navigation>
    <Loader v-if="loading" />
    <div>
      <InstagramGallery :images="images" />
      <v-row class="pa-0">
        <v-col class="video-container">
          <video
            width="100%"
            controls
            src="assets/video/tzagkarakis-germanakis.mp4"
          ></video>
        </v-col>
      </v-row>
    </div>
  </Navigation>
</template>

<script>
import Navigation from "../components/navigation/Navigation.vue";
import Loader from "../components/loader/Loader.vue";
import InstagramGallery from "@/components/instagram/InstagramGallery.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    InstagramGallery,
    Navigation,
    Loader
  },
  mounted() {
    this.$store.commit("carousel/setTitle", "application.menu.gallery");
    this.$store.commit(
      "carousel/setSubtitle",
      "application.menu.gallery.subtitle"
    );
    this.$store.commit("carousel/setImage", "/assets/carousel/carousel_3.jpg");
    this.$store.commit(
      "carousel/setHeaderTitle",
      "application.menu.gallery.subtitle"
    );
    this.$store.commit("carousel/setHeaderImage", "/assets/images/lentas.jpg");
  },
  computed: {
    ...mapGetters("business", ["images"]),
    ...mapGetters("loader", ["loading"])
  }
};
</script>
